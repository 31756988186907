import ThirstTrap from './static/ThirstTrap'

export default function Static() {
  const scrollToContent = () => {
    const target = document.getElementById('callout-content');
    target.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <>
      <div id="scrolldown" className="flex-container flex-column" 
          onClick={scrollToContent}>
          <img 
          className='scrolldown-arrow'
          src="/ui/double-arrow-down.svg" 
          aria-label="scroll down to explore more" 
          alt="Scroll down" 
          width="60"
          style={{ margin: '0.5rem 0 1rem' }}  />
      </div>
      <div id="callout-content">
        <ThirstTrap />
      </div>
    </>
  );
}