import React, { act } from 'react';
import { AppContext } from '../context/AppContext'
import * as THREE from 'three'
import { Vector3 } from 'three';
import { useEffect, useState, useRef } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import { useGLTF, Plane, Box, Sphere, Html, Stats } from '@react-three/drei';
import { gsap } from 'gsap';
import { TextureLoader } from 'three';
import { useLoader } from '@react-three/fiber';
import useCupCausticsMaterial from '../shaders/caustics/CupCausticsMaterial';
import TargetMaterial from '../shaders/target/TargetMaterial';
import GlowMaterial from '../shaders/glow/GlowMaterial';
import SparkleMaterial from '../shaders/sparkle/SparkleMaterial';
import Aquarium from './Aquarium';
import IceMaker from './IceMaker';

import handleInteraction from '../hooks/handleInteraction';
import handleAreaInteraction from '../hooks/handleAreaInteraction';
import handleSurpriseInteraction from '../hooks/handleSurpriseInteraction';
import handleCloseBookInteraction from '../hooks/handleCloseBookInteraction';

export default function PlatformObject() {
    const {
        isFullscreen,
        animating,
        isMobile,
        lookAtPosition,
        targetPositionCamera,
        products,
        areas,
        surprises, 
        activeProduct, setActiveProduct,
        hoveredProduct, setHoveredProduct,
        merchActive, setMerchActive,
        hoveredArea, setHoveredArea,
        areaActive, setAreaActive,
        videoRef,
        setLoadVideo,
        floorLogoAnimationRef, floorLogoMesh,
        bookCoverAnimationRef, bookCoverMesh,
        bookEndAnimationRef, bookEndMesh,
        closeBookRef, closeBookMesh,
        setToggleFAQ,
        cameraRef,
        cameraAnimationCompleted, setCameraAnimationCompleted
    } = React.useContext(AppContext);

    // Add ref to track camera animation state
    const cameraAnimating = useRef(false);

    // Helper function to determine tooltip visibility
    const getTooltipClass = (product) => {
        // Only check cameraAnimating if the product is not active
        if (cameraAnimating.current && activeProduct !== product.id) return 'item-info';
        if (hoveredProduct === product.id ) return 'item-info hovered';
        if (cameraAnimationCompleted && activeProduct === product.id) return 'item-info active';
        return 'item-info';
    };

    // Helper function to determine wrapper class
    const getWrapperClass = (product) => {
        if (cameraAnimating.current && activeProduct !== product.id) return 'pointer-none';
        if (activeProduct === product.id || hoveredProduct === product.id) return 'pointer-active';
        return 'pointer-none';
    };

    // Update handleInteraction to use cameraAnimating
    const handleInteractionWithCamera = (event, productId, products, setActiveProduct, floorLogoMesh, bookCoverMesh, bookEndMesh, closeBookMesh, targetPositionCamera, lookAtPosition, cameraRef, isMobile, animating, setMerchActive, setAreaActive) => {
        // Reset camera when starting animation
        cameraAnimating.current = true; 
        setCameraAnimationCompleted(false);
        // Set active product immediately
        setActiveProduct(productId);
        
        handleInteraction(
            event, 
            productId, 
            products, 
            setActiveProduct, 
            floorLogoMesh, bookCoverMesh, bookEndMesh, closeBookMesh,
            targetPositionCamera, 
            lookAtPosition, 
            cameraRef, 
            isMobile, 
            animating, 
            setMerchActive, 
            setAreaActive,
            setToggleFAQ,
            () => {
                // Callback to set cameraAnimating to false when camera animation completes
                cameraAnimating.current = false;
                setCameraAnimationCompleted(true);
            }
        );
    };

    //CAMERA
    const { raycaster, size, mouse } = useThree();
    const cursor = useRef({ x: 0, y: 0 })
    useEffect(() => {
        const handleMouseMove = (event) => {
            cursor.current.x = (event.clientX / size.width - 0.5)
            cursor.current.y = -(event.clientY / size.height - 0.5)
        }
        window.addEventListener('mousemove', handleMouseMove)

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('mousemove', handleMouseMove)
        }
    }, [size])

    // Update the camera position on each frame
    useFrame(() => {
        if (!animating.current && isFullscreen && cameraRef.current) {
            if (!isMobile) { // Check if the device is not mobile
                cameraRef.current.lookAt(new THREE.Vector3(0, -19, 0)) // look at original position
                targetPositionCamera.current.x = (Math.sin(cursor.current.x * 1.5) * 150);
                targetPositionCamera.current.z = Math.cos(cursor.current.x) * 79;
                targetPositionCamera.current.y = (cursor.current.y * 25) + -6;
                cameraRef.current.position.lerp(targetPositionCamera.current, 0.05);
            } 
        } else if (!animating.current && cameraRef.current) {
            // When animating is false, smoothly transition the camera to a resting position
            const restingPosition = new THREE.Vector3(-20, isMobile ? 40 : -9, isMobile ? 70 : 85); // Set this to your desired resting position
            cameraRef.current.lookAt(lookAtPosition.current)
            cameraRef.current.position.lerp(restingPosition, 0.05);
        } else if (cameraRef.current) {
            cameraRef.current.lookAt(lookAtPosition.current) // look at ref position
            cameraRef.current.position.lerp(targetPositionCamera.current, 0.07);
        }
    })

    //Define the states for interactions
    const [hoveredSurprise, setHoveredSurprise] = useState(null);
    
    // Define Materials
    const cupCausticsMaterial = useCupCausticsMaterial();
    const blueIceMatcapTexture = useLoader(TextureLoader, '/textures/matcaps/blue-ice.jpg');
    const blueIceMatcapMaterial = new THREE.MeshMatcapMaterial({
        matcap: blueIceMatcapTexture,
        side: THREE.DoubleSide,
        transparent: true,
        opacity: 0.25,
    });
    const whiteIceMatcapTexture = useLoader(TextureLoader, '/textures/matcaps/sonic-blue.jpg');
    const whiteIceMatcapMaterial = new THREE.MeshMatcapMaterial({
        matcap: whiteIceMatcapTexture,
        side: THREE.DoubleSide,
        transparent: true,
        opacity: 0.65,
    });
       
    const playTexture = new TextureLoader().load('/ui/play-circle.png');
    const playMaterial = new THREE.MeshBasicMaterial({ 
        map: playTexture,
        transparent: true 
    });
    
    // Load the model
    const model = useGLTF('/models/Lobby_Platform_v19.glb')
    const duckyAnimationRef = useRef();
    const areaSignsAnimationRef = useRef();
    const gummyShark1AnimationRef = useRef();
    const gummyShark2AnimationRef = useRef();
    const gummyShark3AnimationRef = useRef();
    const gummyShark4AnimationRef = useRef();
    const gummyShark5AnimationRef = useRef();
    useEffect(() => {
        // TUMBLER
        // const sonicTumber = model.scene.children.find(child => child.name === 'cup_Baked');
        // sonicTumber.material = SparkleMaterial;
        // CUP
        const sonicCupOut = model.scene.children.find(child => child.name === 'SONIC_CupOut');
        const sonicCupTop = model.scene.children.find(child => child.name === 'SONIC_CupTop');
        const sonicCupCaustics = model.scene.children.find(child => child.name === 'SONIC_CupCaustics');
        const sonicCupLogo = model.scene.children.find(child => child.name === 'SONIC_CupLogo');
        sonicCupCaustics.renderOrder = 1;
        sonicCupOut.renderOrder = 2;
        sonicCupLogo.renderOrder = 3;
        sonicCupTop.renderOrder = 4;
        sonicCupCaustics.material = cupCausticsMaterial;
        sonicCupOut.material = blueIceMatcapMaterial;
        sonicCupTop.material = whiteIceMatcapMaterial;
        // DUCK
        const ducky = model.scene.children.find(child => child.name === 'Duckie');
        ducky.renderOrder = 5;
        if (ducky.material) {
            ducky.material.depthWrite = false;
        }
        const ducky01 = model.scene.children.find(child => child.name === 'Duckie_01');
        duckyAnimationRef.current = ducky01;
        // AREAS
        const areaSigns = model.scene.children.find(child => child.name === 'AreaSigns');
        areaSignsAnimationRef.current = areaSigns;
        // FLOOR LOGO
        const floorLogo = model.scene.children.find(child => child.name === 'SONIC_Floor');
        floorLogoAnimationRef.current = floorLogo;
        // BOOK COVER
        const bookCover = model.scene.children.find(child => child.name === 'BookFront');
        bookCoverAnimationRef.current = bookCover;
        // BOOK END
        const bookEnd = model.scene.children.find(child => child.name === 'BookBack');
        bookEndAnimationRef.current = bookEnd;
        // GUMMY SHARKS
        const gummyShark1 = model.scene.children.find(child => child.name === 'GummyShark1');
        gummyShark1AnimationRef.current = gummyShark1;
        const gummyShark2 = model.scene.children.find(child => child.name === 'GummyShark2');
        gummyShark2AnimationRef.current = gummyShark2;
        const gummyShark3 = model.scene.children.find(child => child.name === 'GummyShark3');
        gummyShark3AnimationRef.current = gummyShark3;
        const gummyShark4 = model.scene.children.find(child => child.name === 'GummyShark4');
        gummyShark4AnimationRef.current = gummyShark4;
        const gummyShark5 = model.scene.children.find(child => child.name === 'GummyShark5');
        gummyShark5AnimationRef.current = gummyShark5;
    }, [model]);

    // HOVER EFFECTS
    const validProducts = products.filter(product => ["GIFT", "GEAR", "GOODS", "GARAGE"].includes(product.label));
    // Update the raycaster on each frame
    useFrame(({ clock }) => {
        // Ensure camera matrices are updated after any programmatic changes
        if (cameraRef.current) {
            cameraRef.current.updateMatrixWorld();
            cameraRef.current.updateProjectionMatrix();

            // Call the function for products and areas
            handleHover(validProducts, setHoveredProduct);
            handleHover(areas, setHoveredArea);
            handleHover(surprises, setHoveredSurprise);

            // Animation updates
            const elapsedTime = clock.getElapsedTime();
            animateObjects(elapsedTime);

            // Update sparkle material time uniform
            if (SparkleMaterial.uniforms) {
                SparkleMaterial.uniforms.uTime.value = elapsedTime /2;
                SparkleMaterial.uniforms.viewVector.value = new THREE.Vector3().subVectors(cameraRef.current.position, new THREE.Vector3(0, -26.2, 0));
            }
        }
    });

    function handleHover(objects, setHoveredObject) {
        if (!cameraRef.current) return;

        // Get the 3D objects associated with the objects
        const object3Ds = objects.map(object => object.target.current);
    
        // Filter out any undefined objects
        const validObjects = object3Ds.filter(object => object !== undefined);
    
        // Update raycaster with current camera and mouse state
        raycaster.setFromCamera(mouse, cameraRef.current);
        const intersects = raycaster.intersectObjects(validObjects, true);
    
        if (intersects.length > 0) {
            // Handle hover
            const intersectedObject = objects.find(object => object.target.current === intersects[0].object);
            setHoveredObject(intersectedObject.id);
        } else {
            setHoveredObject(null);
        }
    }
    
    // ANIMATIONS
    function animateObjects(elapsedTime) {
            // Animate the water caustics
            cupCausticsMaterial.uniforms.uTime.value = elapsedTime / 6.5; 
        
            // Animate the targets
            if (TargetMaterial) {
                TargetMaterial.uniforms.u_time.value = elapsedTime;
            }

            // Animate the glows - only if camera is ready
            if (GlowMaterial && cameraRef.current) {
                GlowMaterial.uniforms.uGlowFactor.value = Math.sin(elapsedTime * 3) * 0.5 + 0.5;
                let viewVector = new Vector3().subVectors(cameraRef.current.position, new THREE.Vector3(-27.5, -25, 31.1));
                GlowMaterial.uniforms.viewVector.value = viewVector;
                GlowMaterial.uniforms.uOpacity.value = animating.current ? '0' : 0.8;
            }

            // Update sparkle material time uniform
            if (SparkleMaterial.uniforms) {
                SparkleMaterial.uniforms.uTime.value = elapsedTime /2;
                SparkleMaterial.uniforms.viewVector.value = new THREE.Vector3().subVectors(cameraRef.current.position, new THREE.Vector3(0, -26.2, 0));
            }

            // Animate other objects
            animateDucky(duckyAnimationRef.current, elapsedTime);
            animateSigns(areaSignsAnimationRef.current, elapsedTime);
            animateGummy1(gummyShark1AnimationRef.current, elapsedTime);
            animateGummy2(gummyShark2AnimationRef.current, elapsedTime);
            animateGummy3(gummyShark3AnimationRef.current, elapsedTime);
            animateGummy4(gummyShark4AnimationRef.current, elapsedTime);
            animateGummy5(gummyShark5AnimationRef.current, elapsedTime);
        }
    
    function animateDucky(ducky, elapsedTime) {
        if (ducky) {
            ducky.rotation.y = elapsedTime / 6.5;
            ducky.rotation.z = 0.02 * Math.sin(elapsedTime);
            ducky.rotation.x = 0.1 * Math.sin(elapsedTime);
        }
    }
    function animateSigns(signs, elapsedTime) {
        if (signs) {
            signs.position.y = 4.5 + 0.1 * Math.sin(elapsedTime * 2);
        }
    }
    function animateGummy1(gummy, elapsedTime) {
        if (gummy) {
            gummy.rotation.y = elapsedTime / 2.5;
            gummy.position.y = 4.5 + 0.5 * Math.sin(elapsedTime / 4);
        }
    }
    function animateGummy2(gummy, elapsedTime) {
        if (gummy) {
            gummy.rotation.y = elapsedTime / 2.5;
            gummy.position.y = 5.5 + 0.4 * Math.sin(elapsedTime / 4.5);
        }
    }
    function animateGummy3(gummy, elapsedTime) {
        if (gummy) {
            gummy.rotation.y = elapsedTime / 2.5;
            gummy.position.y = 1.7 + 0.6 * Math.sin(elapsedTime / 3);
        }
    }
    function animateGummy4(gummy, elapsedTime) {
        if (gummy) {
            gummy.rotation.y = elapsedTime / 2.5;
            gummy.position.y = 2.1 + 0.5 * Math.sin(elapsedTime / 3.5);
        }
    }
    function animateGummy5(gummy, elapsedTime) {
        if (gummy) {
            gummy.rotation.y = elapsedTime;
            gummy.position.y = 4 + 0.6 * Math.sin(elapsedTime / 4);
        }
    }

    return (
        <>
            {/* Platform */}
            <mesh>
                <primitive
                    object={model.scene}
                    scale={[3.5, 3.5, 3.5]}
                    position={[0, -26.2, 0]}
                    rotation={[0, -Math.PI * 0.25, 0]}
                />
            </mesh>
            <Aquarium />
            <IceMaker />
            <Plane args={[1, 1]}
                position={[-15.01, -21.37, 39.99]}
                rotation={[0.05,-0.52,0.02]}>
                <primitive attach="material" object={playMaterial} visible={activeProduct === 'tv' ? false : true} />
            </Plane>
            {/* Targets */}
            {products.map((product, index) => {
                if (["LIMITED", "GEAR", "GOODS", "GARAGE"].includes(product.label)) {
                    const productPosition = new THREE.Vector3(...product.position);
                    return (
                        <mesh key={`${index}_target`}
                            onClick={(event) => {
                                event.stopPropagation();
                                handleInteractionWithCamera(
                                    event, 
                                    product.id, 
                                    products, 
                                    setActiveProduct, 
                                    floorLogoMesh, bookCoverMesh, bookEndMesh, closeBookMesh,
                                    targetPositionCamera, 
                                    lookAtPosition, 
                                    cameraRef, 
                                    isMobile, 
                                    animating, 
                                    setMerchActive, 
                                    setAreaActive, 
                                    setCameraAnimationCompleted
                                );
                            }}
                            onPointerEnter={(event) => {
                                event.stopPropagation();
                                document.body.style.cursor = 'pointer'
                            }}
                            onPointerLeave={() => { document.body.style.cursor = 'default' }}
                        >
                            <Plane
                            args={isMobile ? [6, 6] : [3, 3]}
                            scale={activeProduct === "gear" || activeProduct === "goods" ? [0.3, 0.3, 0.3] : (activeProduct === "garage" ? [0.6, 0.6, 0.6] : [1, 1, 1])}
                                position={new Vector3().copy(productPosition).add(new Vector3(0, 0.3, 0))}
                                rotation={activeProduct === "gear" || activeProduct === "garage" ? [0, 0.9, 0] : [0, 0, 0]}
                            >
                                <primitive attach="material" object={TargetMaterial} visible={!merchActive} />
                            </Plane>
                        </mesh>
                    );
                }
            })}
            {/* Products */}
            {products.map((product, index) => {
                if (["LIMITED", "GEAR", "GOODS", "GARAGE"].includes(product.label)) {
                    const productPosition = new THREE.Vector3(...product.position);
                    return (
                        <mesh key={`${index}_product`}>
                            <Box ref={product.target} args={[...product.size]}
                                position={new Vector3().copy(productPosition)}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleInteractionWithCamera(
                                        event, 
                                        product.id, 
                                        products, 
                                        setActiveProduct, 
                                        floorLogoMesh, bookCoverMesh, bookEndMesh, closeBookMesh,
                                        targetPositionCamera, 
                                        lookAtPosition, 
                                        cameraRef, 
                                        isMobile, 
                                        animating, 
                                        setMerchActive, 
                                        setAreaActive,
                                        setToggleFAQ
                                    );
                                }}
                                onPointerEnter={(event) => {
                                    event.stopPropagation();
                                    document.body.style.cursor = 'pointer'
                                }}
                                onPointerLeave={() => { document.body.style.cursor = 'default' }}
                            >
                                <meshBasicMaterial visible={false} />
                                <Html
                                    wrapperClass={getWrapperClass(product)}
                                    position={activeProduct === 'rubber-duck' ? [0.2, -2.2, 0] : (activeProduct === 'support-hat' ? [0, -0.1, 0] : [0, -0.4, 0])}
                                    key={`html-${targetPositionCamera.current.x}-${targetPositionCamera.current.y}-${targetPositionCamera.current.z}`}
                                    onPointerEnter={(event) => {
                                        event.stopPropagation();
                                        document.body.style.cursor = 'pointer'
                                    }}
                                    onPointerLeave={() => { document.body.style.cursor = 'default' }}
                                >
                                    <div 
                                    role="button"
                                    aria-label="View product"
                                    className={getTooltipClass(product)}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        handleInteractionWithCamera(
                                            event, 
                                            product.id, 
                                            products, 
                                            setActiveProduct, 
                                            floorLogoMesh, bookCoverMesh, bookEndMesh, closeBookMesh,
                                            targetPositionCamera, 
                                            lookAtPosition, 
                                            cameraRef, 
                                            isMobile, 
                                            animating, 
                                            setMerchActive, 
                                            setAreaActive,
                                            setToggleFAQ,
                                        );
                                    }} dangerouslySetInnerHTML={{ __html: product.title }} />
                                </Html>
                            </Box>
                        </mesh>
                    );
                }
            })}
            {/* Areas */}
            {areas.map((area, index) => {
                const areaPosition = new THREE.Vector3(...area.position);
                return (
                    <mesh key={`${index}_area`}>
                        <Box ref={area.target} args={[...area.size]}
                            position={new Vector3().copy(areaPosition)}
                            onClick={(event) => {
                                event.stopPropagation();
                                handleAreaInteraction(
                                    event, 
                                    raycaster, mouse, cameraRef, 
                                    areas, 
                                    activeProduct, setActiveProduct, 
                                    floorLogoMesh, bookCoverMesh, bookEndMesh, closeBookMesh,
                                    targetPositionCamera, 
                                    lookAtPosition, 
                                    isMobile, 
                                    animating, 
                                    merchActive, setMerchActive, 
                                    areaActive, setAreaActive,
                                    hoveredArea, setHoveredArea,
                                    setToggleFAQ
                                );
                            }}
                            onPointerEnter={(event) => {
                                event.stopPropagation();
                                document.body.style.cursor = 'pointer'
                            }}
                            onPointerLeave={() => { document.body.style.cursor = 'default' }}
                        >
                            <meshBasicMaterial visible={false} />
                        </Box>
                    </mesh>
                );
            })}
            {/* Surprises */}
            <Box args={[12, 23, 23]}
                position={[-1, -38, 1]}
                rotation={[0, -0.4, 0]}
                ref={closeBookRef}
                onClick={(event) => {
                    event.stopPropagation();
                    handleCloseBookInteraction(
                        event, 
                        floorLogoMesh,
                        bookCoverMesh,
                        bookEndMesh,
                        closeBookMesh,
                        targetPositionCamera,
                        lookAtPosition,
                        cameraRef,
                        isMobile,
                        animating
                    );
                    animating.current = false;
                    setActiveProduct(null);
                    setMerchActive(false);
                    setAreaActive(false);
                }}
                onPointerEnter={(event) => {
                    event.stopPropagation();
                    document.body.style.cursor = 'pointer'
                }}
                onPointerLeave={() => { document.body.style.cursor = 'default' }}
                >
                    <meshStandardMaterial visible={false} />
            </Box>
            {surprises.map((surprise, index) => {
                const surprisePosition = new THREE.Vector3(...surprise.position);
                return (
                    <mesh key={`${index}_surprise`}>
                        <Sphere ref={surprise.target} args={[...surprise.size]}
                            position={new Vector3().copy(surprisePosition)}
                            onClick={(event) => {
                                event.stopPropagation();
                                handleSurpriseInteraction(
                                    event,
                                    raycaster, mouse, cameraRef, 
                                    surprises,
                                    activeProduct, setActiveProduct,
                                    videoRef,
                                    setLoadVideo,
                                    floorLogoMesh,
                                    bookCoverMesh,
                                    bookEndMesh,
                                    closeBookMesh,
                                    targetPositionCamera,
                                    lookAtPosition,
                                    isMobile,
                                    animating,
                                    setMerchActive,
                                    setAreaActive,
                                );
                            }}
                            onPointerEnter={(event) => {
                                event.stopPropagation();
                                document.body.style.cursor = 'pointer'
                            }}
                            onPointerLeave={() => { document.body.style.cursor = 'default' }}
                        >
                            <primitive attach="material" object={GlowMaterial}  />
                            {/* <meshStandardMaterial wireframe={true} color="hotpink" /> */}
                        </Sphere>
                    </mesh>
                );
            })}
            {/* Stats
            <Stats className="stats" />
            <SparkleParticles 
                count={10} // How many sparkles
                position={[-21.5, -23.5, 12.5]}  // Where the sparkles are centered
                spread={[6, 3, 6]}     // How far particles spread on each axis
                baseOpacity={0.4} // Base opacity of particles (0-1)
                opacityRange={1.0} // How much opacity varies (0-1)
                rotationSpeed={2.1} // How fast particles rotate 
                noiseSpeed={5.5} // How fast noise animates
                noiseIntensity={0.9} // How much noise affects particles
                starScale={0.3} // Base size of star points
                pulseSpeed={3.5} // How fast star size animates
                pointCount={10} // Number of star points
                pulseMin={0.1} // Minimum pulse size
                pulseMax={2.0} // Maximum pulse size
            /> */}
        </>
    );
}
useGLTF.preload('/models/Lobby_Platform_v19.glb')
