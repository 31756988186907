import React, { useEffect, useRef } from "react";
import { AppContext } from "../../context/AppContext";

export default function ThirstTrap() {
  const { limited } = React.useContext(AppContext);
  const initialized = useRef(false);

  useEffect(() => {
    if (initialized.current) return;

    const scriptURL =
      "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";

    function loadScript() {
      var script = document.createElement("script");
      script.async = true;
      script.src = scriptURL;
      script.onload = ShopifyBuyInit;
      document.body.appendChild(script);
    }

    function ShopifyBuyInit() {
      var client = window.ShopifyBuy.buildClient({
        domain: "carhopshop.myshopify.com",
        storefrontAccessToken: "d61f6d4f2bdba47b23e8346ec85c57d5",
      });

      window.ShopifyBuy.UI.onReady(client).then(function (ui) {
        initialized.current = true;

        const baseOptions = {
          moneyFormat: "${{amount}}",
          options: {
            product: {
              iframe: false,
              images: {
                maxWidth: 2048,
                showNext: true,
                showPrevious: true,
              },
              styles: {
                product: {
                  "@media (min-width: 601px)": {
                    "max-width": "100%",
                    "margin-left": "0",
                    "margin-bottom": "50px",
                  },
                },
                title: {
                  color: "#008ad8",
                  font: "bold 20px/1 'Poppins', sans-serif",
                  cursor: "pointer",
                  "margin-top": "30px"
                },
                price: {
                  color: "#008ad8",
                  font: "bold 20px/1 'Poppins', sans-serif",
                },
                button: {
                  "@media (max-width: 768px)": {
                    "margin-top": "-20px !important",
                  },
                  ":hover": {
                    "background-color": "#ffffff",
                    color: "#008ad8",
                    border: "3px solid #008ad8",
                  },
                  "background-color": "#008ad8",
                  font: "bold 20px/1 'Poppins', sans-serif",
                  color: "#ffffff",
                  border: "3px solid #008ad8",
                  ":focus": {
                    "background-color": "#008ad8",
                    font: "bold 20px/1 'Poppins', sans-serif",
                    color: "#ffffff",
                    border: "3px solid #008ad8",
                  },
                  "border-radius": "30px",
                  "padding-left": "20px",
                  "padding-right": "20px",
                },
              },
              buttonDestination: "modal",
              contents: {
                img: true,
                imgWithCarousel: false,
                title: false,
                price: false,
                options: false,
                button: false,
                description: false,
              },
              templates: {
                img: `
                  <div class="{{data.classes.product.img}}" style="cursor: pointer;" onclick="this.querySelector('.shopify-buy__btn').click()">
                    <img src="{{data.currentImage.src}}" class="card-img" loading="lazy" />
                    <button class="shopify-buy__btn" style="display: none;"></button>
                  </div>
                `,
                title: `
                  <div class="{{data.classes.product.title}}" style="cursor: pointer;" onclick="this.querySelector('.shopify-buy__btn').click()">
                    <h3 class="{{data.classes.product.title}}">{{data.title}}</h3>
                    {{#data.selectedVariant.price}}
                    <div class="{{data.classes.product.price}}">{{data.selectedVariant.formattedPrice}}</div>
                    {{/data.selectedVariant.price}}
                    <button class="shopify-buy__btn" style="display: none;"></button>
                  </div>
                `,
                button: `
                  <button class="{{data.classes.product.button}} shopify-buy__btn" style="width: 100%;">{{data.buttonText}}</button>
                `,
                modalImage: `
                  <div class="{{data.classes.modal.img}}">
                    {{#data.images}}
                      <img src="{{src}}" class="shopify-buy__modal-img" alt="{{data.title}}" />
                    {{/data.images}}
                  </div>
                `,
              },
            },
            productSet: {
              styles: {
                products: {
                  "@media (min-width: 601px)": {
                    "margin-left": "-20px",
                  },
                },
              },
            },
            modalProduct: {
              contents: {
                img: false,
                imgWithCarousel: true,
                title: true,
                price: true,
                options: true,
                button: true,
                buttonWithQuantity: true,
                description: true,
              },
              layout: 'horizontal',
              width: '100%',
              
              styles: {
                overlay: {
                  "background-color": "rgba(0, 0, 0, 0.85)",
                },
                modal: {
                  "background-color": "#ffffff",
                  "border-radius": "30px",
                  padding: "40px",
                  "max-width": "1200px",
                  margin: "40px auto",
                },
                img: {
                  "max-width": "none",
                  width: "100%",
                  height: "auto",
                  "margin-bottom": "20px",
                  "border-radius": "15px",
                },
                imgWithCarousel: {
                  "max-width": "none",
                  width: "100%",
                  height: "auto",
                  "margin-bottom": "20px",
                  "border-radius": "15px",
                  img: {
                    width: "100%",
                    height: "auto",
                    display: "block",
                    "margin-bottom": "20px",
                    "border-radius": "15px",
                  }
                },
                title: {
                  font: "bold 30px/1 'Poppins', sans-serif",
                },
                price: {
                  color: "#008ad8",
                  font: "bold 24px/1 'Poppins', sans-serif",
                },
                product: {
                  "@media (min-width: 601px)": {
                    "max-width": "100%",
                    margin: "0",
                    color: "#008ad8",
                    font: "bold 48px/1 'Poppins', sans-serif",
                    "text-transform": "uppercase",
                    "border-radius": "30px",
                    padding: "30px 30px 20px 20px",
                  },
                },
                options: {
                  "margin-top": "15px",
                },
                description: {
                  font: "normal 16px/1.3 'Poppins', sans-serif",
                  "text-transform": "none",
                },
                quantityInput: {
                  font: "normal 18px/1 'Poppins', sans-serif",
                  height: "44px",
                  "border-radius": "30px",
                  border: "3px solid #008ad8",
                  color: "#008ad8",
                  "margin-right": "0",
                  display: "inline-block",
                  "vertical-align": "top",
                },
                button: {
                  ":hover": {
                    "background-color": "#008ad8",
                    color: "#ffffff",
                  },
                  "background-color": "#008ad8",
                  font: "bold 16px/1 'Poppins', sans-serif",
                  color: "#ffffff",
                  border: "3px solid #008ad8 !important",
                  height: "44px",
                  ":focus": {
                    "background-color": "#008ad8",
                    color: "#ffffff",
                  },
                  "border-radius": "30px",
                  "padding-left": "20px",
                  "padding-right": "20px",
                  display: "inline-block",
                  "vertical-align": "top",
                },
                buttonWrapper: {
                  display: "flex",
                  "align-items": "center",
                  gap: "10px",
                },
              },
              text: {
                button: "Add to cart",
              },
              order: [
                "img",
                "imgWithCarousel",
                "title",
                "price",
                "buttonWithQuantity",
                "options",
                "description",
              ],
            },
            option: {},
            cart: {
              styles: {
                title: {
                  color: "#008ad8",
                  font: "bold 20px/1 'Poppins', sans-serif",
                },
                button: {
                  ":hover": {
                    "background-color": "#008ad8",
                    "border-color": "#008ad8",
                    color: "#ffffff",
                  },
                  "background-color": "#ffffff",
                  border: "3px solid #008ad8",
                  font: "bold 20px/1 'Poppins', sans-serif",
                  color: "#008ad8",
                  ":focus": {
                    "background-color": "#008ad8",
                    color: "#ffffff",
                  },
                  "border-radius": "30px",
                  "padding-left": "20px",
                  "padding-right": "20px",
                },
              },
              text: {
                total: "Subtotal",
                button: "Checkout",
              },
            },
            toggle: {
              styles: {
                toggle: {
                  "background-color": "#e40345",
                  ":hover": {
                    "background-color": "#cd033e",
                  },
                  ":focus": {
                    "background-color": "#cd033e",
                  },
                },
              },
            },
          },
        };

        // Create components for products in the limited array
        limited.forEach((product) => {
          if (product.shopifyId === undefined) return;

          const imageNode = document.getElementById(
            `product-image-${product.shopifyId}`
          );
          const titleNode = document.getElementById(
            `product-title-${product.shopifyId}`
          );

          if (imageNode && titleNode) {
            // Create image component with custom template
            const imageOptions = {
              ...baseOptions,
              options: {
                ...baseOptions.options,
                product: {
                  ...baseOptions.options.product,
                  contents: {
                    ...baseOptions.options.product.contents,
                    img: true,
                    price: false,
                    button: false,
                  },
                  images: true,
                  viewData: {
                    images: true
                  }
                },
                modalProduct: {
                  ...baseOptions.options.modalProduct,
                  contents: {
                    ...baseOptions.options.modalProduct.contents,
                    img: false,
                    imgWithCarousel: true,
                    title: true,
                    price: true,
                    options: true,
                    description: true,
                    button: true,
                    buttonWithQuantity: true,
                  },
                  images: true,
                  viewData: {
                    images: true
                  }
                }
              },
            };

            // Create title button options
            const titleButtonOptions = {
              ...imageOptions,
              options: {
                ...imageOptions.options,
                product: {
                  ...imageOptions.options.product,
                  contents: {
                    ...imageOptions.options.product.contents,
                    img: false,
                    button: false,
                    title: true,
                    price: true,
                  },
                  buttonDestination: "modal",
                  templates: {
                    ...baseOptions.options.product.templates,
                  },
                }
              },
            };

            // Create image component
            ui.createComponent("product", {
              id: product.shopifyId,
              node: imageNode,
              ...imageOptions,
            });

            // Create title button component
            ui.createComponent("product", {
              id: product.shopifyId,
              node: titleNode,
              ...titleButtonOptions,
            });
          }
        });
      });
    }

    if (window.ShopifyBuy) {
      if (window.ShopifyBuy.UI) {
        ShopifyBuyInit();
      } else {
        loadScript();
      }
    } else {
      loadScript();
    }
  }, [limited]);

  return (
    <>
      <div id="static" className="blue-bkg">
        <img
          src="./static/thirst-headline.png"
          alt="Thirst Trap"
          className="static-hero"
          loading="lazy"
        />
        <div className="static-container">
          <div className="flex-center">
            <h2>KEEP IT CUTE. KEEP IT COZY.</h2>
            <p style={{ maxWidth: "610px" }}>
              We already know you've got great taste. Now you can prove it by
              treating yourself and the snacks in your life to craveably
              comfortable new loungewear. Shop the collection.
            </p>
          </div>
          <div className="cards cards-auto">
            {limited.map((product) => (
              <div className="card card-product" key={product.id}>
                {product.shopifyId ? (
                  <>
                    <div
                      id={`product-image-${product.shopifyId}`}
                      className="product-component"
                    ></div>
                    <div
                      id={`product-title-${product.shopifyId}`}
                      className="product-component"
                    ></div>
                  </>
                ) : null}
              </div>
            ))}
          </div>
        </div>
        <div className="static-cta">
          <a className="static-cta-button static-btn" href="https:sonicdrivein.com/menu" target="_blank" rel="noopener noreferre">ORDER YOUR FAVE DRINKS NOW</a>
          <img
            src="./static/thirst-cta.png"
            alt="Lounge and Sip"
            loading="lazy"
          />
        </div>
      </div>
    </>
  );
}
