import React, { useEffect } from 'react';
import { AppContext } from '../context/AppContext'
import { gsap } from 'gsap';
import FAQs from './FAQs';
import handleCameraAnimation from '../hooks/handleCameraAnimation';

export default function Popup() {
  const {
    isMobile,
    setIsFullscreen,
    activeProduct, setActiveProduct,
    setShowPopup,
    merchActive, setMerchActive,
    areaActive, setAreaActive,
    animating,
    lookAtPosition,
    targetPositionCamera,
    cameraRef,
    products,
    areas,
    surprises,
    buttonRefs,
    videoRef,
    setLoadVideo,
    toggleFAQ, setToggleFAQ,
    setCameraAnimationCompleted
  } = React.useContext(AppContext);

  // Helper function to check if a product has valid camera positions
  const hasValidCameraPositions = (product) => {
    return product && 
           product.camera && 
           product.position && 
           Array.isArray(product.camera) && 
           Array.isArray(product.position) &&
           product.camera.length === 3 && 
           product.position.length === 3 &&
           product.camera.every(coord => typeof coord === 'number') &&
           product.position.every(coord => typeof coord === 'number');
  };

  // Filter products that have camera positions
  const getNavigableProducts = () => {
    return products.filter(product => 
      ["GIFT", "GEAR", "GOODS", "GARAGE"].includes(product.label) &&
      hasValidCameraPositions(product)
    );
  };

  function setNextProduct() {
    const currentProduct = products.find(p => p.id === activeProduct);
    const navigableProducts = getNavigableProducts();
    
    // Find the next product in the full products list
    const currentIndex = products.findIndex(p => p.id === activeProduct);
    const nextProduct = products[(currentIndex + 1) % products.length];
    
    // Set the next product
    setActiveProduct(nextProduct.id);
    setMerchActive(true);
    setCameraAnimationCompleted(false);
    setToggleFAQ(false);
    
    // Only attempt camera animation if the next product has valid camera positions
    if (hasValidCameraPositions(nextProduct)) {
      handleCameraAnimation(
        nextProduct.id,
        products,
        targetPositionCamera,
        lookAtPosition,
        cameraRef,
        isMobile,
        animating,
        setCameraAnimationCompleted
      );
    }
  }

  function setPrevProduct() {
    const currentProduct = products.find(p => p.id === activeProduct);
    const navigableProducts = getNavigableProducts();
    
    // Find the previous product in the full products list
    const currentIndex = products.findIndex(p => p.id === activeProduct);
    const prevIndex = (currentIndex - 1 + products.length) % products.length;
    const prevProduct = products[prevIndex];
    
    // Set the previous product
    setActiveProduct(prevProduct.id);
    setMerchActive(true);
    setCameraAnimationCompleted(false);
    setToggleFAQ(false);
    
    // Only attempt camera animation if the previous product has valid camera positions
    if (hasValidCameraPositions(prevProduct)) {
      handleCameraAnimation(
        prevProduct.id,
        products,
        targetPositionCamera,
        lookAtPosition,
        cameraRef,
        isMobile,
        animating,
        setCameraAnimationCompleted
      );
    }
  }

  function setPopupCloseStates() {
    animating.current = false;
    setMerchActive(false);
    setAreaActive(false);
    setShowPopup(false);
    setToggleFAQ(false);
    if(isMobile) {
      gsap.to('.popup-container', { duration: 0.1, y: 700, ease: 'expo.inOut' });
    } else {
      gsap.to('.popup-container', { duration: 0.1, x: -700, ease: 'back' });
    }
    setActiveProduct(null);

    // // Reset the camera's lookAt position and target position
    // lookAtPosition.current.set(0, -19, 0);
    // targetPositionCamera.current.set(-20, isMobile ? 40 : -9, isMobile ? 70 : 85);

    // Find the index of the active product
    const currentIndex = products.findIndex(product => product.id === activeProduct);

    // Find the next button in the buttonRefs array
    const nextButton = buttonRefs[(currentIndex + 1) % buttonRefs.length].current;

    // Shift focus to the next button
    if (nextButton) {
      nextButton.focus();
    }
  }

  const handleExploreButtonClick = () => {
    document.body.style.overflowY = 'scroll';
    setToggleFAQ(false);
    setIsFullscreen(false);
    animating.current = false;
    setCameraAnimationCompleted(false);
    if (isMobile) {
      gsap.to('.popup-container', { duration: 0, y: 700 }); 
    } else {
      gsap.to('.popup-container', { duration: 0, x: -700 });
    }
    setActiveProduct(null);

    if (videoRef.current) {
      videoRef.current.pause();
    }
    setLoadVideo(false);

    // Delay the scrolling until after the overflowY property has been set
    setTimeout(() => {
      const element = document.getElementById("callout-content");
      if (element) element.scrollIntoView({ behavior: 'smooth' });
    }, 0);
  }

  useEffect(() => {
    const scriptURL = "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";

    function loadScript() {
      var script = document.createElement("script");
      script.async = true;
      script.src = scriptURL;
      script.onload = ShopifyBuyInit;
      document.body.appendChild(script);
    }

    function ShopifyBuyInit() {
      var client = window.ShopifyBuy.buildClient({
        domain: 'carhopshop.myshopify.com',
        storefrontAccessToken: 'd61f6d4f2bdba47b23e8346ec85c57d5',
      });

      window.ShopifyBuy.UI.onReady(client).then(function (ui) {
        const productOptions = {
          moneyFormat: "%24%7B%7Bamount%7D%7D",
          options: {
            product: {
              styles: {
                product: {
                  "@media (min-width: 601px)": {
                    "max-width": "100%",
                    "margin-left": "0",
                    "margin-bottom": "50px",
                  },
                },
                title: {
                  "color": "#008ad8",
                  "font": "bold 20px/1 'Poppins', sans-serif",
                },
                price: {
                  "color": "#008ad8",
                  "font": "bold 20px/1 'Poppins', sans-serif",
                },
                button: {
                  "@media (max-width: 768px)": {
                    "margin-top":"-20px !important",
                  },
                  ":hover": {
                    "background-color": "#ffffff",
                    "color": "#008ad8",
                    "border": "3px solid #008ad8",
                  },
                  "background-color": "#008ad8",
                  "font": "bold 20px/1 'Poppins', sans-serif",
                  "color": "#ffffff",
                  "border": "3px solid #008ad8",
                  ":focus": {
                    "background-color": "#008ad8",
                    "font": "bold 20px/1 'Poppins', sans-serif",
                    "color": "#ffffff",
                    "border": "3px solid #008ad8",
                  },
                  "border-radius": "30px",
                  "padding-left": "20px",
                  "padding-right": "20px",
                },
              },
              "buttonDestination": "modal",
              "contents": {
                "img": false,
                "title": false,
                "price": false,
                "options": false,
              },
              "text": {
                "button": "SHOP IT",
              },
            },
            "productSet": {
              "styles": {
                "products": {
                  "@media (min-width: 601px)": {
                    "margin-left": "-20px"
                  }
                }
              }
            },
            modalProduct: {
              contents: {
                img: false,
                imgWithCarousel: true,
                title: true,
                price: true,
                options: true,
                button: true,
                buttonWithQuantity: true,
                description: true,
              },
              layout: 'horizontal',
              width: '100%',
              
              styles: {
                overlay: {
                  "background-color": "rgba(0, 0, 0, 0.85)",
                },
                modal: {
                  "background-color": "#ffffff",
                  "border-radius": "30px",
                  padding: "40px",
                  "max-width": "1200px",
                  margin: "40px auto",
                },
                img: {
                  "max-width": "none",
                  width: "100%",
                  height: "auto",
                  "margin-bottom": "20px",
                  "border-radius": "15px",
                },
                imgWithCarousel: {
                  "max-width": "none",
                  width: "100%",
                  height: "auto",
                  "margin-bottom": "20px",
                  "border-radius": "15px",
                  img: {
                    width: "100%",
                    height: "auto",
                    display: "block",
                    "margin-bottom": "20px",
                    "border-radius": "15px",
                  }
                },
                title: {
                  font: "bold 30px/1 'Poppins', sans-serif",
                },
                price: {
                  color: "#008ad8",
                  font: "bold 24px/1 'Poppins', sans-serif",
                },
                product: {
                  "@media (min-width: 601px)": {
                    "max-width": "100%",
                    margin: "0",
                    color: "#008ad8",
                    font: "bold 48px/1 'Poppins', sans-serif",
                    "text-transform": "uppercase",
                    "border-radius": "30px",
                    padding: "30px 30px 20px 20px",
                  },
                },
                options: {
                  "margin-top": "15px",
                },
                description: {
                  font: "normal 16px/1.3 'Poppins', sans-serif",
                  "text-transform": "none",
                },
                quantityInput: {
                  font: "normal 18px/1 'Poppins', sans-serif",
                  height: "44px",
                  "border-radius": "30px",
                  border: "3px solid #008ad8",
                  color: "#008ad8",
                  "margin-right": "0",
                  display: "inline-block",
                  "vertical-align": "top",
                },
                button: {
                  ":hover": {
                    "background-color": "#008ad8",
                    color: "#ffffff",
                  },
                  "background-color": "#008ad8",
                  font: "bold 16px/1 'Poppins', sans-serif",
                  color: "#ffffff",
                  border: "3px solid #008ad8 !important",
                  height: "44px",
                  ":focus": {
                    "background-color": "#008ad8",
                    color: "#ffffff",
                  },
                  "border-radius": "30px",
                  "padding-left": "20px",
                  "padding-right": "20px",
                  display: "inline-block",
                  "vertical-align": "top",
                },
                buttonWrapper: {
                  display: "flex",
                  "align-items": "center",
                  gap: "10px",
                },
              },
              text: {
                button: "Add to cart",
              },
              order: [
                "img",
                "imgWithCarousel",
                "title",
                "price",
                "buttonWithQuantity",
                "options",
                "description",
              ],
            },
            "option": {},
            "cart": {
              "styles": {
                "title": {
                  "color": "#008ad8",
                  "font": "bold 20px/1 'Poppins', sans-serif",
                },
                "button": {
                  ":hover": {
                    "background-color": "#008ad8",
                    "border-color": "#008ad8",
                    "color": "#ffffff",
                  },
                  "background-color": "#ffffff",
                  "border": "3px solid #008ad8",
                  "font": "bold 20px/1 'Poppins', sans-serif",
                  "color": "#008ad8",
                  ":focus": {
                    "background-color": "#008ad8",
                    "color": "#ffffff",
                  },
                  "border-radius": "30px",
                  "padding-left": "20px",
                  "padding-right": "20px",
                },
              },
              "text": {
                "total": "Subtotal",
                "button": "Checkout",
              },
            },
            "toggle": {
              "styles": {
                "toggle": {
                  "background-color": "#e40345",
                  ":hover": {
                    "background-color": "#cd033e",
                  },
                  ":focus": {
                    "background-color": "#cd033e",
                  },
                },
              },
            },
          },
        };

        // Create individual product components
        products.forEach(product => {
          if (product.shopifyId === undefined) return;
          
          // // For GIFT labeled products, create a collection component instead
          // if (product.label === "GIFT") {
          //   ui.createComponent("collection", {
          //     id: product.shopifyCollectionId,
          //     node: document.getElementById(`product-component-${product.shopifyId}`),
          //     options: {
          //       product: productOptions.options.product,
          //       modalProduct: productOptions.options.modalProduct,
          //       cart: productOptions.options.cart,
          //       toggle: productOptions.options.toggle,
          //       collection: {
          //         ...productOptions.options.productSet,
          //         buttonDestination: 'modal',
          //         text: {
          //           button: 'VIEW COLLECTION',
          //         },
          //       },
          //     },
          //   });
          // } else {
            // Regular product component for non-GIFT items
            ui.createComponent("product", {
              id: product.shopifyId,
              node: document.getElementById(`product-component-${product.shopifyId}`),
              ...productOptions
            });
          // }
        });
      });
    }

    if (window.ShopifyBuy) {
      if (window.ShopifyBuy.UI) {
        ShopifyBuyInit();
      } else {
        loadScript();
      }
    } else {
      loadScript();
    }
  }, []);

  const popupRef = React.useRef(null);
  useEffect(() => {
    if (activeProduct) {
      popupRef.current.focus();
    }
  }, [activeProduct]);

  const closeButtonRef = React.useRef(null);
  useEffect(() => {
    if (activeProduct) {
      const handleKeyDown = (event) => {
        if (event.key === 'Tab' && !event.shiftKey) {
          event.preventDefault();
          const currentIndex = products.findIndex(product => product.id === activeProduct);
      const nextButton = buttonRefs[(currentIndex + 1) % buttonRefs.length].current;
      if (nextButton) {
        nextButton.focus();
      }
    }
      };

      closeButtonRef.current.addEventListener('keydown', handleKeyDown);

      return () => {
        closeButtonRef.current.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [activeProduct]);

  return (
    <>
    <button className={`scene-close-button ${merchActive ? 'active' : ''}`}
    aria-label="Close the pop-up"
    onClick={(event) => {
      event.stopPropagation();
      setPopupCloseStates();
    }}></button>
      <div ref={popupRef} className={`${activeProduct} ${merchActive || areaActive ? 'product-popup' : ''} popup-container ${toggleFAQ ? 'active' : ''}`} tabIndex={-1}>
        {products.map((product) => {
          if (["LIMITED", "GEAR", "GOODS", "GARAGE"].includes(product.label)) {
            return (
              <div
                key={product.id}
                className={activeProduct === product.id ? 'product-info' : 'hidden'}>
                  <div className='content'>
                    <div>
                      {product.imagePath && 
                      <img src={product.imagePath}
                        alt={product.title}
                        width="250" />}
                      <div className="product-text">
                        <h2 dangerouslySetInnerHTML={{ __html: product.title }} />
                        <p>{product.desc}</p>
                      </div>
                    </div>
                    {product.shopifyId ?
                      <div
                        id={`product-component-${product.shopifyId}`}
                        className="product-component"></div>
                      : (product.id === 'limited' ? 
                        <div className='exit-component'>
                          <button 
                            aria-label="View the Limited Edition Collection" 
                            className='shopit-button' 
                            onClick={() => handleExploreButtonClick()}>
                              VIEW SHOP
                          </button>
                        </div> 
                        : <span style={{height: '40px', display: 'block'}}></span>)
                    }
                  </div>
                  <div className='advance-buttons'>
                    <button
                      aria-label="Prev product"
                      className="btn prev-button"
                      onClick={(event) => {
                        event.stopPropagation();
                        setPrevProduct();
                      }} />
                    <button
                      aria-label="Next product"
                      className="btn next-button"
                      onClick={(event) => {
                        event.stopPropagation();
                        setNextProduct();
                      }} />
                  </div>
              </div>
            )
          }
          return null;
        })}
        
        {surprises.map((surprise) => (
          <div
            key={surprise.id}
            className={activeProduct === surprise.id ? 'product-info' : 'hidden'}>
              <div className='content'>
                <div>
                  {surprise.imagePath ? <img src={surprise.imagePath}
                    alt={surprise.alt}
                    width="250" /> : null}
                  <div className="product-text">
                    {surprise.title && <h2>{surprise.title}</h2>}
                    <p>{surprise.desc}</p>
                  </div>
                </div>
                {surprise.url ?
                  <div>
                    <a className="shopit-button" 
                      href={surprise.url} 
                      target={surprise.urlTarget} 
                      aria-label={surprises.ariaLabel}>{surprise.cta}</a>
                  </div>
                  : null}
                {surprise.title === 'FAQS' ? <FAQs /> : null}
            </div>
            <div className='advance-buttons'>
              <button
                aria-label="Prev product"
                className="btn prev-button"
                onClick={(event) => {
                  event.stopPropagation();
                  setPrevProduct();
                }} />
              <button
                aria-label="Next product"
                className="btn next-button"
                onClick={(event) => {
                  event.stopPropagation();
                  setNextProduct();
                }} />
            </div>
          </div>
        ))}
        <button
          ref={closeButtonRef}
          aria-label="Close the pop-up"
          className="btn close-button"
          onClick={(event) => {
            event.stopPropagation();
            setPopupCloseStates();
          }} />
      </div>
    </>
  )
}
