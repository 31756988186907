import React, { useContext, useEffect } from "react";
import Brandbar from "../components/Brandbar";
import { useParams } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { useNavigate } from 'react-router-dom';

const Product = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { products, limited } = useContext(AppContext);
  // Combine regular products and limited products
  const allProducts = [...products, ...limited];

  // Find the product that matches the URL parameter
  const product = allProducts.find((p) => p.id === id);

  useEffect(() => {
    let shopifyComponent;
    const scriptURL =
      "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";

    function loadScript() {
      // Check if script already exists
      if (document.querySelector(`script[src="${scriptURL}"]`)) {
        ShopifyBuyInit();
        return;
      }

      const script = document.createElement("script");
      script.async = true;
      script.src = scriptURL;
      script.onload = ShopifyBuyInit;
      document.body.appendChild(script);
    }

    function ShopifyBuyInit() {
      var client = window.ShopifyBuy.buildClient({
        domain: "carhopshop.myshopify.com",
        storefrontAccessToken: "d61f6d4f2bdba47b23e8346ec85c57d5",
      });

      window.ShopifyBuy.UI.onReady(client).then(function (ui) {
        // Check if component already exists
        const existingComponent = document.querySelector(".shopify-buy-frame");
        if (existingComponent) {
          return;
        }

        if (product && product.shopifyId) {
          const productOptions = {
            moneyFormat: "%24%7B%7Bamount%7D%7D",
            options: {
              product: {
                contents: {
                  img: false,
                  imgWithCarousel: true,
                  title: true,
                  price: true,
                  options: true,
                  button: true,
                  buttonWithQuantity: true,
                  description: true,
                },
                layout: "horizontal",
                width: "100%",
                styles: {
                  overlay: {
                    "background-color": "rgba(0, 0, 0, 0.85)",
                  },
                  modal: {
                    "background-color": "#ffffff",
                    "border-radius": "30px",
                    padding: "40px",
                    "max-width": "1200px",
                    margin: "40px auto",
                  },
                  img: {
                    "max-width": "none",
                    width: "100%",
                    height: "auto",
                    "margin-bottom": "20px",
                    "border-radius": "15px",
                  },
                  imgWithCarousel: {
                    "max-width": "none",
                    width: "100%",
                    height: "auto",
                    "margin-bottom": "20px",
                    "border-radius": "15px",
                    img: {
                      width: "100%",
                      height: "auto",
                      display: "block",
                      "margin-bottom": "20px",
                      "border-radius": "15px",
                    },
                  },
                  title: {
                    font: "bold 30px/1 'Poppins', sans-serif",
                    "color": "#008ad8",
                  },
                  price: {
                    color: "#008ad8",
                    font: "bold 24px/1 'Poppins', sans-serif",
                  },
                  product: {
                    "@media (min-width: 601px)": {
                      "max-width": "100%",
                      margin: "0",
                      "min-height": "440px",
                      color: "#008ad8",
                      font: "bold 48px/1 'Poppins', sans-serif",
                      "text-transform": "uppercase",
                      "border-radius": "30px",
                      padding: "0",
                    },
                  },
                  options: {
                    "margin-top": "15px",
                  },
                  description: {
                    font: "normal 16px/1.3 'Poppins', sans-serif",
                    "text-transform": "none",
                  },
                  quantityInput: {
                    font: "normal 18px/1 'Poppins', sans-serif",
                    height: "44px",
                    "border-radius": "30px",
                    border: "3px solid #008ad8",
                    color: "#008ad8",
                    "margin-right": "0",
                    display: "inline-block",
                    "vertical-align": "top",
                  },
                  button: {
                    ":hover": {
                      "background-color": "#008ad8",
                      color: "#ffffff",
                    },
                    "background-color": "#008ad8",
                    font: "bold 16px/1 'Poppins', sans-serif",
                    color: "#ffffff",
                    border: "3px solid #008ad8 !important",
                    height: "44px",
                    ":focus": {
                      "background-color": "#008ad8",
                      color: "#ffffff",
                    },
                    "border-radius": "30px",
                    "padding-left": "20px",
                    "padding-right": "20px",
                    display: "inline-block",
                    "vertical-align": "top",
                  },
                  buttonWrapper: {
                    display: "flex",
                    "align-items": "center",
                    gap: "10px",
                  },
                },
                text: {
                  button: "Add to cart",
                },
                order: [
                  "img",
                  "imgWithCarousel",
                  "title",
                  "price",
                  "buttonWithQuantity",
                  "options",
                  "description",
                ],
              },
              cart: {
                styles: {
                  button: {
                    ":hover": {
                      "background-color": "#008ad8",
                      "border-color": "#008ad8",
                      color: "#ffffff",
                    },
                    "background-color": "#ffffff",
                    border: "3px solid #008ad8",
                    font: "bold 20px/1 'Poppins', sans-serif",
                    color: "#008ad8",
                    ":focus": {
                      "background-color": "#008ad8",
                      color: "#ffffff",
                    },
                    "border-radius": "30px",
                    "padding-left": "20px",
                    "padding-right": "20px",
                  },
                },
              },
              toggle: {
                styles: {
                  toggle: {
                    "background-color": "#e40345",
                    ":hover": {
                      "background-color": "#cd033e",
                    },
                    ":focus": {
                      "background-color": "#cd033e",
                    },
                  },
                },
              },
            },
          };

          shopifyComponent = ui.createComponent("product", {
            id: product.shopifyId,
            node: document.getElementById(
              `product-component-${product.shopifyId}`
            ),
            ...productOptions,
          });
        }
      });
    }

    if (window.ShopifyBuy) {
      if (window.ShopifyBuy.UI) {
        ShopifyBuyInit();
      } else {
        loadScript();
      }
    } else {
      loadScript();
    }
    // Cleanup function
    return () => {
      if (shopifyComponent) {
        shopifyComponent.destroy();
      }
    };
  }, [product]);

  if (!product) {
    useEffect(() => {
      // Redirect to the homepage
      navigate('/');
    }, [navigate]);
    return null;
  }

  if (
    product.label === "LTO" ||
    product.label === "GEAR" ||
    product.label === "GOODS" ||
    product.label === "GARAGE"
  ) {
    return (
      <>
        <div className="product-page">
          <div className="product-container">
            {product.shopifyId && (
              <div
                id={`product-component-${product.shopifyId}`}
                className="product-component"
              ></div>
            )}
            <a href="/" className="back-button">
              Shop the Collection
            </a>
          </div>
        </div>
        <Brandbar />
      </>
    );
  } else {
    useEffect(() => {
      // Redirect to the homepage
      navigate('/');
    }, [navigate]);
    return null;
  }
};

export default Product;
